import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbvue/lib/css/mdb.min.css'
import '@/assets/css/main.css'
import 'sweetalert2/dist/sweetalert2.min.css';
//////////////////////////////////////////////

import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css'; // 필요하다면 추가

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSweetalert2 from 'vue-sweetalert2'
import GmapVue from 'gmap-vue'
import FullCalendar from '@fullcalendar/vue'
///import dayGridPlugin from '@fullcalendar/daygrid'
//import interactionPlugin from '@fullcalendar/interaction'

//import axios from '@/axios'
// FullCalendar 플러그인 등록
Vue.component('full-calendar', FullCalendar);
Vue.use(GmapVue, {
  load: {
    key: 'AIzaSyBaCZs3-FE_Td_u6Zvv8r2TTC814evV-1g',
    libraries: 'places',
    customCallback: 'MyCustomCallback',
    region: 'KO',
    language: 'ko',
  },
  autoBindAllEvents: false,
  installComponents: true,
  dynamicLoad: false,
})

const options = {
  confirmButtonColor: '#021754',
  cancelButtonColor: '#818181',
};

Vue.use(VueSweetalert2, options);


Vue.config.productionTip = false;
//axios.js에서 관리됨.
//Vue.config.devtools = true;
//axios.defaults.baseURL = 'http://13.209.14.50:3000';
//axios.defaults.baseURL = 'http://43.203.209.122:3000';
//axios.defaults.baseURL = 'http://localhost:3000';

import Notify from 'mdbvue/lib/components/Notify'
Vue.use(Notify)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
